import panelsOnPop from '../../app/signals/panelsOnPop'
import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { send } from '../../socket/actions'
import { getLangCode, getLocaleRequest } from '../actions'
import { loadBroadcastNotifications } from '../../chat/actions'

export default [
  panelsOnPop,
  ({ router, props }) => {
    const pathname = location.pathname.split('/')
    pathname[1] = props.locale
    router.goTo(`${pathname.join('/')}${location.search}`)
  },
  set(state`intl.lang`, props`locale`),
  getLangCode,
  () => ({ useCache: false }),
  getLocaleRequest, {
    success: [],
  },
  set(state`chat.broadcastDialogs`, {}),
  loadBroadcastNotifications,
  ({ props }) => ({ params: { lang: props.locale, country: null }, method: 'setLangAndCountry' }),
  send,
]
