import { wowzaApiURl } from './apiURLUtil'
import { getSessionId } from '../cookies'
import { showSnackbarNew } from '../../modules/app/factories'
import { CONFIRM_SNACKBAR } from '../snackbarUtil'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { closeAllPanels, createGetVipPanel } from '../../components/Panels/createPanelUtil'
import { openPosters } from '../navigateUtil'
import { getLocaleValue } from '../localeUil'
import { deleteUserImmediately } from './deleteUtil'

export function deleteWithConfirm(key, cb) {
  showSnackbarNew(getLocaleValue(key), 5000, CONFIRM_SNACKBAR, {
    buttonKey: 'confirm',
    onClick: () => {
      cb()
    },
  })
}

export function vipOlyOption(key, cb) {
  showSnackbarNew(getLocaleValue(key || 'vip_only_option'), 5000, CONFIRM_SNACKBAR, {
    buttonKey: 'vip_buy',
    onClick: () => {
      createGetVipPanel()
    },
  })
}

export const PERMANENT_DELETE = 'permanent_delete'

export function deleteAccount(deactivate, uid) {
  if (deactivate){
    fetch(wowzaApiURl('/vb/user'), {
      method: 'POST',
      body: new URLSearchParams({ sid: getSessionId(), delete: true, reason: 'any' })
    }).catch(e => console.warn('catch error', e))
  } else {
    deleteUserImmediately(uid)
  }
  closeAllPanels()
  openPosters()
  setStoreWrapper('deletedMode', deactivate ? true : PERMANENT_DELETE)
  setTimeout(() => {
    setStoreWrapper('genderSelectScreen', true)
  }, 500)
}

export function restoreAccount() {
  fetch(wowzaApiURl('/vb/user'), {
    method: 'POST',
    body: new URLSearchParams({ sid: getSessionId(), restore: true, reason: 'any' })
  }).catch(e => console.warn('catch error', e)).finally(() => {
    closeAllPanels()
    setStoreWrapper('deletedMode', '')
    // setStoreWrapper('genderSelectScreen', false)
    setStoreWrapper('auth.isAuthComplete', false)
    setTimeout(() => {
      callSequence('auth.getAuthInfo')
    }, 500)
  })
}
