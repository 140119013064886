import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import onNewMessage from './onNewMessage'
import { hideBadUrlsInDialog } from './checkChatMessage'
import { getMyUid } from '../../../utils/my'
import { isMe } from '../../../utils'
import { MESSAGE_TYPE_AWARD, MESSAGE_TYPE_MEDIA } from '../../../utils/manyConst'
import { getLocaleValue } from '../../../utils/localeUil'
import updateDialogView from './updateDialogView'
import { addUnreadDirectMessage } from '../../../utils/chat/dialogUtil'
import { dialogScrollToEnd } from '../../../utils/chat/chatMessagesManager'
import { playUserVoice } from '../../../utils/ai/textToSpeech'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'

const { stickersPacks, soundUrl } = config

export default async ({ props, store, get }) => {
  if (!props.params) return
  const msgData  = props.params
  
  msgData.text = hideBadUrlsInDialog(msgData.message)
  if (msgData.translations) {
    for (const key in msgData.translations) {
      msgData.translations[key] = hideBadUrlsInDialog(msgData.translations[key])
    }
  }
  
  const dialog_id = props.dialog_id || msgData.dialog_id
  // const from = msgData.from
  // const to = msgData.to

  const dialogs = get(state`chat.dialogs`) || []
  let dialog = dialogs.find(d => d.dialog_id === dialog_id)
  if (!dialog) {
    dialog = {
      date: new Date().toISOString(),
      dialog_id: dialog_id,
      last_message_id: msgData.id,
      message: msgData.message,
      metadata:msgData.metadata,
      uid: msgData.uid,
      unread_message_count: 0,
      users_in_dialog: [ msgData.uid, getMyUid() ]
    }
    dialogs.push(dialog)
  }
  if (!dialog.messages) {
    dialog.messages = []
  }

  loadUsers([msgData.uid], async () => {
    dialog.messages.push({
      date: new Date().toISOString(),
      dialog_id: dialog_id,
      message_id: msgData.id,
      message: msgData.message,
      metadata:msgData.metadata,
      message_type: msgData.message_type,
      translations: msgData.translations,
      uid: msgData.uid
    })
    dialog.message = msgData.message
    dialog.date = new Date().toISOString()
    
    let notInDialog = true
    const panels = get(state`app.panels`)
    if (panels.length > 0 && panels[panels.length - 1].type === 'dialog') {
      notInDialog = false

      //TODO поменять на диалоги с мульти юзерами и проверку по id диалога
      if (get(state`app.dialogUserId`) === msgData.uid){
        updateDialogView(dialog_id)
      }
    }
    
    if (notInDialog) {
      if (!isMe(msgData.uid)) {
        dialog.unread_message_count++
        if (get(state`auth.pushSettings.privateMsg`)) {
          try {
            const s = new Audio(soundUrl + 'private_message_source.mp3')
            await s.play()
          } catch (e) {
            console.log('sound not allowed')
          }
        }

      }
      if (!isMe(msgData.uid) && msgData.message_type !== MESSAGE_TYPE_AWARD) {
        onNewMessage({
          store, get,
          props: {
            roomId: get(state`app.roomId`),
            msgData: {
              from: msgData.uid,
              postId: msgData.id,
              private: true,
              message_type: msgData.message_type,
              sticker: msgData.message_type === 'sticker' ? msgData.message : undefined,
              text: msgData.message_type === MESSAGE_TYPE_MEDIA ? getLocaleValue('dialog_media_file') : msgData.text,
              to: get(state`auth.uid`)
            }
          }
        })
        if (!isMe(msgData.uid)) {
          addUnreadDirectMessage(msgData.uid, 1)
        }
      }
    }

    playUserVoice(msgData.text, msgData.uid, ()=>{
      store.set('chat.dialogs', dialogs)
      if (getState('cyberTyping') === msgData.uid) {
        setStoreWrapper('cyberTyping', null)
      }

      // if (isMe(msgData.uid)) {
        dialogScrollToEnd()
      // }
    })
  })
}
