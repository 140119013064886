import { state } from 'cerebral'

const GENDER_CATEGORY = 'people'
export default [
  ({ props, store, get }) => {
    const { tagId, clearCategory, categoryId } = props
    let selectedTags = get(state`upload.selectedTags`)
    const category = get(state`intl.postCategories.${categoryId || '_'}`) || {}

    if (tagId) {
      if (selectedTags.includes(tagId)) {
        selectedTags = selectedTags.filter(item => item !== tagId)
      } else {
        selectedTags.push(tagId)
      }
    }
    if (clearCategory || category.onlyOneTag || categoryId === GENDER_CATEGORY) {
      const catTags = get(state`upload.categoriesTags.${clearCategory || categoryId}.topTags`) || []
      selectedTags = selectedTags.filter(item => item === tagId || !catTags.find(tag => tag.tagId === item))
    }
    store.set('upload.selectedTags', selectedTags)
  }
]
