import { when } from 'cerebral/factories'
import { state } from 'cerebral'

import config from '@/config'
import { getSessionId } from '@/utils'

import { LEVEL_0 } from '../../../utils/subscriptionUtil'
import { savePostsData } from './onGetPosts'
import { PROFILE_TAG, STREAM_THEME_TAG } from '@/utils/postUtils'
import {
  allBlogRatings,
  allPostRatings,
  allSubsRatings,
  isOnlyApprovedPosts
} from '../../../utils/ageRating/ageRatingUtil'
import { imAdmin } from '../../../utils/adminUtil'
import { getDatingParams, getNoGenderMismatchParam, getProfilesParams, getSubsParams } from '../getPostsRequestParams'
import { checkSelectedFeed, getSelectedTags } from '../../filters/actions/feedUtil'
import { getState } from '../../../utils/StoreManager'
import { loadUsers } from '../../../utils/userLoader'
import { isUID } from '../../../utils/isUID'
import { isRestrictedMode, isUV } from '../../../utils/api/PWAUtil'
import { GAMING_TAG } from '../../../utils/postUtils'
import { isCDNDisable } from '../../../utils/appUtil'
import { isDatingView, isFansFeed } from '../../../utils/post/postViewUtil'
import { createDatingInstructionsPanel } from '../../../components/Panels/createPanelUtil'
import { isDatingApp } from '../../../utils/appConfigUtil'
import { setCyberPosters } from '../../../utils/cyber/cyberUtil'
import { isMe } from '../../../utils'
import { loadUidsNearMe } from '../../../utils/generatedProfiles/loadUidsNearMe'
import { isAIGeneratedActive } from '../../../utils/userUtil'

import { getFansFeed, getILikeFeed, getMatches } from '../../../utils/api/dating/datingApi'
import { firstLoadStreams } from '../../socket/actions'
import { isModeration } from '../../../utils/moderationUtil'


const { ftCoreUrl } = config

export const TYPE_CHAT = 'chat'
export const TYPE_LIVE_ONLY = 'live_only'
export const TYPE_DATING = 'dating'
export const TYPE_I_LIKE = 'i_like'
export const TYPE_CYBER = 'Cyber'
export const TYPE_CYBER_VIP = 'Cyber_VIP'
export const TYPE_REAL_DATING = 'real_dating'
export const TYPE_ONLINE = 'online'
export const TYPE_FANS = 'fans'
export const TYPE_MATCH = 'match'
export const TYPE_VIDEO_ONLY = 'video_only'
export const TYPE_SUBS_POSTS = 'subscriptions'
export const TYPE_SPONSORS = 'sponsors'
export const TYPE_TOP_W = 'top_w'
export const TYPE_TOP_M = 'top_m'
export const TYPE_TOP_M_L = 'top_m_l'
export const TYPE_SUBSCRIPTIONS = 'subscriptions'
export const TYPE_VIP = 'vip_filter'

export const EMPTY_I_LIKE = 'emptyILike'

const UV_TAG = 1347339
const FM_TAG = 1177345
const FC_TAG = 1177368

export function getUserFeed() {
  return getState('posts.selectedFeed')
}

export const isVIPFilterSelected = filters => {
  for (const group of filters) {
    const groupId = Object.keys(group)[0]
    for (const btn of group[groupId]) {
      if (btn.selected && btn.id === 'vip_filter') {
        return true
      }
    }
  }
  return false
}

const getExcludeTags = filters => {
  let result = []
  for (const group of filters) {
    const groupId = Object.keys(group)[0]
    for (const btn of group[groupId]) {
      if (btn.selected && btn.excludeTags) {
        result = result.concat(btn.excludeTags)
      }
    }
  }

  return result.length === 0 ? null : result
}

const getTagsCondition = filters => {
  for (const group of filters) {
    const groupId = Object.keys(group)[0]
    for (const btn of group[groupId]) {
      if (btn.selected && btn.ORmode) {
        return { condition: 'OR', cTags: btn.tagsId }
      }
    }
  }
  return { condition: null, cTags: [] }
}

const getPeriod = filters => {//'recently', 'weekly', 'monthly'
  for (const group of filters) {
    if (!group.period) continue
    for (const btn of group.period) {
      if (btn.selected) {
        return btn.period
      }
    }
    return null
  }
  return null
}

export const getTags = (filters, selectedTags, priorityTags) => {
  let noMainTag = false
  let result = []

  for (const group of filters) {
    const groupId = Object.keys(group)[0]
    for (const btn of group[groupId]) {
      if (btn.selected && btn.tagsId) {
        if (btn.tagsId.length > 1) {
          result = btn.tagsId
        } else {
          result = result.concat(btn.tagsId)
        }
      }
      if (btn.selected && btn.noMainTag) {
        noMainTag = true
      }
    }
  }
  result = result.concat(selectedTags)
  if (!noMainTag) {
    result = result.concat(priorityTags)
  }
  return result
}

export const getPostType = filters => {//'live', 'photo', 'video', 'chat'
  let postsTypeSelected = false

  try {
    for (const group of filters) {
      if (group.period) {
        continue
      }
      if (!group.type && !group.subscriptions) {
        const groupId = Object.keys(group)[0]
        for (const btn of group[groupId]) {
          if (btn.selected) {
            postsTypeSelected = true
            break
          }
        }
        continue
      }
      if (!group.type) {
        continue
      }
      for (const btn of group.type) {
        if (btn.selected) {
          switch (btn.id) {
            case 'live_only':
              return 'live'
            case 'photo_only':
              return 'photo'
            case TYPE_VIDEO_ONLY:
              return 'video'
            case 'chat_only':
              return TYPE_CHAT
            case TYPE_DATING:
              return TYPE_DATING
            case TYPE_REAL_DATING:
              return TYPE_REAL_DATING
            case TYPE_ONLINE:
              return TYPE_ONLINE
            case TYPE_FANS:
              return TYPE_FANS
            case TYPE_I_LIKE:
              return TYPE_I_LIKE
            case TYPE_MATCH:
              return TYPE_MATCH
            case TYPE_SPONSORS:
              return TYPE_SPONSORS
            case TYPE_TOP_M:
              return TYPE_TOP_M
            case TYPE_TOP_W:
              return TYPE_TOP_W
          }
        }
      }
    }
  } catch (e) {
    console.warn('getPostType error', e)
  }
  if (postsTypeSelected) return 'posts'
  return null
}

const addCustomFilterParams = (filters, lang) => {
  let params = ''
  for (const group of filters) {
    if (group.type) {
      for (const btn of group.type) {
        if (btn.selected) {
          switch (btn.id) {
            case 'locale_filter':
              params = `&originalLang=${lang}`
              break
            case 'vip_filter':
              params = '&onlyVip=true'
              break
          }
        }
      }
    }
  }
  return params
}

export const getUserSubsPosts = async ({ store, get }) => {
  let queryString = `${ftCoreUrl}/api/v1/posts/new?limit=30`

  queryString += '&userFeed=1'
  // const sid = getSessionId()
  // if (sid) {
  //   queryString += `&sid=${getSessionId()}`
  // }
  if (!isOnlyApprovedPosts()) {
    queryString += '&withOnModeration=true'
    queryString += '&withNotInteresting=true'
  }

  const excludeTags = get(state`posts.tagsIdExcluded`) || []
  excludeTags.push(STREAM_THEME_TAG)
  // queryString += `&excludeTags=${excludeTags}`

  queryString += `&ageRating=${allSubsRatings}`

  queryString += `&lang=${get(state`intl.lang`)}`

  try {
    const response = await fetch(queryString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      }
    })
    if (!response.ok) {
      return
    }
    const result = await response.json()

    const posts = result.posts

    savePostsData({ store, get, props: { posts } })
    const subs = get(state`auth.subs`)
    // posts.forEach(post => {
    //   if (!subs[post.uid]) {
    //     subs[post.uid] = { subscriptionType: LEVEL_0 }
    //   }
    // })
    store.set('auth.subs', subs)
  } catch (e) {
    console.warn('load feed posts error', e)
  }
}

export const getTopPosts = async ({ store, get }) => {
  // if (!getState('auth.isAuthComplete')){
  //   return
  // }
  store.set('posts.loadingPostsStatus', 'loading')

  if (checkSelectedFeed(TYPE_CYBER) || checkSelectedFeed(TYPE_LIVE_ONLY)) {
    if (firstLoadStreams && !isDatingApp()) {
      return
    }
    setCyberPosters()
    return
  }

  if (checkSelectedFeed(TYPE_REAL_DATING) && isAIGeneratedActive()) {
    loadUidsNearMe()
    return
  }

  if (checkSelectedFeed(TYPE_MATCH) && isAIGeneratedActive()) {
    getMatches()
    return
  }

  if (checkSelectedFeed(TYPE_FANS) && isAIGeneratedActive()) {
    getFansFeed()
    return
  }

  if (checkSelectedFeed(TYPE_I_LIKE) && isAIGeneratedActive()) {
    getILikeFeed()
    return
  }

  if (isDatingView() && !isDatingApp()) {
    createDatingInstructionsPanel()
  }
  let queryString = `${ftCoreUrl}/api/v1/posts/`
  let topPosts = []
  const sid = getSessionId()

  if (!sid) {
    return
  }

  const feed = get(state`posts.selectedFeed`)

  if (feed === TYPE_DATING) {
    //сортировка по новизне
    // queryString += 'new?limit=30'

    //сортировка рядом
    // queryString += 'near?limit=30'

    //сортировка по расстоянию
    queryString += 'near/geo?limit=30&withoutDislikes=true'

    queryString += '&' + getDatingParams(false, true)
    queryString += '&' + getNoGenderMismatchParam()
  } else if (feed === TYPE_REAL_DATING) {
    //сортировка рядом
    queryString += 'near?limit=30&withoutDislikes=true'
    queryString += '&' + getDatingParams(false, true)
    queryString += '&' + getNoGenderMismatchParam()
  } else if (feed === TYPE_LIVE_ONLY) {
    //сортировка по юзеру
    // queryString = `${ftCoreUrl}/api/v1/posts/new`
    if (isRestrictedMode()) {
      queryString = `${ftCoreUrl}/api/v1/posts/new?ageRating=12,14&limit=30&tagsCondition=OR&tags=` + GAMING_TAG //+',' + ART_TAG
      getSelectedTags().push(GAMING_TAG)
      // getSelectedTags().push(ART_TAG)
    } else {
      queryString = `${ftCoreUrl}/api/v1/posts/similar/user`

      queryString += '?limit=30&withoutDislikes=true'
      queryString += '&' + getDatingParams(false, true, true)
      if (sid) {
        queryString += `&sid=${sid}`
      }
      queryString += '&' + getNoGenderMismatchParam()
    }
  } else if (feed === TYPE_ONLINE) {
    //сортировка рядом
    queryString += 'last-visit?limit=30&withoutDislikes=true'
    queryString += '&' + getDatingParams(false, true)
    queryString += '&' + getNoGenderMismatchParam()
  } else if (feed === TYPE_FANS) {
    // queryString += 'new?limit=30'
    queryString += 'new?limit=30&withoutDislikes=true'
    queryString += '&' + getProfilesParams(TYPE_FANS, true)
  } else if (feed === TYPE_MATCH) {
    queryString += 'match-authors?limit=30'
    queryString += '&' + getProfilesParams(TYPE_MATCH, true)
  } else if (feed === TYPE_SPONSORS) {
    queryString += 'last-visit?limit=30'
    queryString += '&' + getDatingParams(true)
    queryString += '&fromVip=true'
  } else if (feed === TYPE_SUBS_POSTS) {
    queryString += 'new?limit=30'
    queryString += '&' + getSubsParams()
  } else if (feed === TYPE_I_LIKE) {
    queryString += 'new?limit=30'
    queryString += '&tags=' + PROFILE_TAG
    queryString += '&' + getSubsParams()
  } else {
    const filters = get(state`posts.filters`)
    // const userFeed = getUserFeed(filters)

    const period = getPeriod(filters)

    if (period) {
      if (period === 'recently') {
        queryString += 'new?limit=30'
      } else {
        queryString += `top?limit=30&period=${period}`
      }
    } else {
      queryString += 'new?limit=30'
    }

    // if (userFeed) {
    //   const subsCount = get(state`auth.subsCount`) || 0
    //   if (subsCount === 0) {
    //     queryString += '&promoAuthors=true'
    //     queryString += `&ageRating=${allPostRatings}`
    //   } else {
    //     queryString += '&userFeed=1'
    //     queryString += `&ageRating=${allSubsRatings}`
    //     if(!isOnlyApprovedPosts()){
    //       //для остальных только безопасные по версии алгорима
    //       if (!isTWA()){
    //         queryString += '&isNotAdult=true&withOnModeration=true&withNotInteresting=true'
    //       } else {
    //         queryString += '&withNotInteresting=true'
    //       }
    //     }
    //   }
    // } else {
    queryString += `&ageRating=${allPostRatings}`
    // }

    if (sid) {
      queryString += `&sid=${sid}`
    }

    const excludeTags = getExcludeTags(filters) || []
    const tagsIdExcluded = get(state`posts.tagsIdExcluded`) || []
    excludeTags.push(STREAM_THEME_TAG)
    const excludeAllTags = excludeTags.concat(tagsIdExcluded)

    if (isUV()) {
      excludeTags.push(FM_TAG, FC_TAG)
    } else {
      excludeTags.push(UV_TAG)
    }
    queryString += `&excludeTags=${excludeAllTags}`

    const subject = get(state`app.subject`)
    if (subject) {
      queryString += `&subject=${subject}`
    }
    queryString += `&lang=${get(state`intl.lang`)}`
    const postType = getPostType(filters)

    if (!isOnlyApprovedPosts() && postType !== 'video') {
      queryString += '&withOnModeration=true'
      queryString += '&isNotAdult=true'
      queryString += '&withNotInteresting=true'
    }

    switch (postType) {
      case 'photo':
        queryString += '&postType=0'
        break
      case 'video':
        queryString += '&postType=1'
        break
    }

    queryString += addCustomFilterParams(filters, get(state`intl.lang`))

    const { condition, cTags } = getTagsCondition(filters)
    if (condition) {
      queryString += `&tagsCondition=${condition}&tags=${cTags}`
    } else {
      const priorityTags = get(state`posts.priorityTags`)

      const tags = getTags(filters, get(state`posts.selectedTags`), priorityTags)
      if (tags && tags.length > 0) {
        queryString += `&tags=${tags}`
      }
    }

    if (get(state`posts.userTags`).length > 0) {
      const userTags = get(state`posts.userTags`)
      queryString += `&userTags=${userTags}`
    }
  }

  if (!queryString.includes('excludeTags')) {
    const et = []
    if (isUV()) {
      et.push(FM_TAG, FC_TAG)
    } else {
      et.push(UV_TAG)
    }
    queryString += `&excludeTags=${et}`
  }

  if (isCDNDisable()) {
    queryString += '&proxyUrls=1'
  }
  topPosts = get(state`posts.topPosts`) || []
  const offset = topPosts.length
  if (offset && !isDatingView()) {
    queryString += `&page=${Math.floor(offset / 30)}`
  }

  try {
    const response = await fetch(queryString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': sid
      },
    })
    if (!response.ok) {
      return
    }
    const result = await response.json()

    let posts = result.posts
    savePostsData({ store, get, props: { posts } })

    const authorUids = []
    posts.map(post => {
      authorUids.push(post.uid)
    })

    loadUsers(authorUids, () => {
      if (feed !== get(state`posts.selectedFeed`)) {
        return
      }
      topPosts = topPosts.concat(posts.map(post => post.postId))
      if (isFansFeed()) {
        if (posts.length < 30) {
          topPosts.push(EMPTY_I_LIKE)
        }
      }
      // if(isDatingView()) {
      //   store.set('posts.topPosts', ['20d629367e29411b7f2e7f93821ae5bf', 'e6ca826dcf83d6cd1b69b344788843bd'])
      // } else {
      store.set('posts.topPosts', topPosts)
      // }

      if (feed === get(state`posts.selectedFeed`)) {
        store.set('posts.loadingPostsStatus', posts.length === 0 ? 'loaded_all' : 'loaded')
      }
    })
  } catch (e) {
    console.warn('load top posts error:', e)
  }
}

export const getUserPosts = async ({ store, get, props }) => {
  if (props.uid && !isUID(props.uid)) {
    props.uid = props.uid.split('_')[1]
  }
  if (!props.uid || get(state`posts.allUserPostsLoaded.${props.uid}`)) {
    return
  }

  store.set('posts.loadingUserPostsStatus', 'loading')

  if (!get(state`posts.userPosts.${props.uid}`)) {
    store.set('posts.userPosts.' + props.uid, [])
  }

  let queryString = isModeration
    ? `${ftCoreUrl}/api/v1/posts/moderation?fromUid=${props.uid}`
    : `${ftCoreUrl}/api/v1/posts/profile?fromUid=${props.uid}`

  // const sid = getSessionId()
  // if (sid) {
  //   queryString += `&sid=${sid}`
  // }
  //если не ТВА и не особый режим, или мои посты, то спросим все публикаци
  if (!isOnlyApprovedPosts() || isMe(props.uid)) {
    queryString += '&withOnModeration=true'
    queryString += '&withNotInteresting=true'
  }

  if (!isMe(props.uid)) {
    queryString += '&isNotAdult=true'
  }

  //если это мой блог, то просим все публикации
  queryString += `&ageRating=${props.uid === get(state`auth.uid`) ? [12, 14, 16, 18] : allBlogRatings}`

  let userPostsPage = get(state`posts.userPostsLoadedPages.${props.uid}`) || 0

  queryString += '&limit=30'
  queryString += `&page=${userPostsPage}`

  userPostsPage++
  store.set(`posts.userPostsLoadedPages.${props.uid}`, userPostsPage)

  queryString += `&lang=${get(state`intl.lang`)}`

  if (!imAdmin()) {
    const tagsIdExcluded = get(state`posts.tagsIdExcluded`) || []
    queryString += `&excludeTags=${tagsIdExcluded}`
  }

  let result
  try {
    const response = await fetch(queryString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    if (!response.ok) {
      return
    }
    result = await response.json()
  } catch (e) {
    return
  }

  const posts = result.posts
  savePostsData({ store, get, props: { posts: posts, saveToUser: true } })
  store.set('posts.loadingUserPostsStatus', posts.length === 0 ? 'loaded_all' : 'loaded')
  if (posts.length < 30) {
    store.set('posts.allUserPostsLoaded.' + props.uid, true)
  }
}

export const getSimilarPosts = async ({ store, get, props }) => {
  if (!props.currentPostId || get(state`posts.allSimilarPostsLoaded.${props.currentPostId}`)) {
    return
  }

  store.set('posts.loadingSimilarPostsStatus', 'loading')

  if (!get(state`posts.similarPosts.${props.currentPostId}`)) {
    store.set('posts.similarPosts.' + props.currentPostId, [])
  }

  let queryString = `${ftCoreUrl}/api/v1/posts/similar/${props.currentPostId}`

  queryString += `?ageRating=${allBlogRatings}`
  // queryString += '&withoutDislikes=true'

  queryString += '&' + getDatingParams(false, true, false, true)
  queryString += '&' + getNoGenderMismatchParam()

  const sid = getSessionId()
  if (sid) {
    queryString += `&sid=${sid}`
  }
  // queryString += '&withOnModeration=true&withNotInteresting=1'

  const similarPosts = get(state`posts.similarPosts.${props.currentPostId}`)
  const similarPostsPage = get(state`posts.allSimilarPostsPages.${props.currentPostId}`) || 0

  queryString += '&limit=30'
  const offset = similarPosts.length
  if (offset) {
    const page = Math.max(similarPostsPage + 1, Math.floor(offset / 30))
    store.set(`posts.allSimilarPostsPages.${props.currentPostId}`, page)
    queryString += `&page=${page}`
  }
  queryString += `&lang=${get(state`intl.lang`)}`

  if (!imAdmin()) {
    const tagsIdExcluded = get(state`posts.tagsIdExcluded`) || []
    queryString += `&excludeTags=${tagsIdExcluded}`
  }

  let result
  try {
    const response = await fetch(queryString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    if (!response.ok) {
      return
    }
    result = await response.json()
  } catch (e) {
    return
  }

  const posts = result.posts

  savePostsData({ store, get, props: { posts: posts, similarTo: props.currentPostId } })
  store.set('posts.loadingSimilarPostsStatus', posts.length === 0 ? 'loaded_all' : 'loaded')
  if (posts.length < 30) {
    store.set('posts.loadingUserPostsStatus', 'loaded_all')
    store.set('posts.allSimilarPostsLoaded.' + props.currentPostId, true)
  }
}

export const getMorePosts = [
  when(state`posts.loadingPostsStatus`, status => status === 'loading' || status === 'loaded_all'), {
    true: [],
    false: [getTopPosts]
  }
]

export const getMoreUserPosts = [
  when(state`posts.loadingUserPostsStatus`, status => status === 'loading' || status === 'loaded_all'), {
    true: [],
    false: [getUserPosts]
  }
]

export const getMoreSimilarPosts = [
  when(state`posts.loadingSimilarPostsStatus`, status => status === 'loading' || status === 'loaded_all'), {
    true: [],
    false: [getSimilarPosts]
  }
]
