import { setStoreWrapper } from './StoreManager'
import { sendAmplitudeEvent } from './GAEvent'

let audioPlayer = null // Глобальная переменная для хранения объекта аудиоплеера

export const PLAY_STATUS_LOADING = 'loading'
export const PLAY_STATUS_PLAYING = 'playing'

let playPromise

export function playAudio(url) {
  if (playPromise) {
    return
  }
  // Проверяем, был ли создан аудиоплеер
  if (audioPlayer !== null) {
    // Если да, останавливаем воспроизведение
    if (!playPromise) {
      audioPlayer.pause()
    }
  }

  // Создаем новый аудио плеер
  audioPlayer = new Audio(url)

  // Обработчик события ошибки воспроизведения
  audioPlayer.onerror = function () {
    setStoreWrapper('playStatus', null)
    console.log('soundPlayError')
  }

  // Обработчик события окончания воспроизведения
  audioPlayer.onended = function () {
    audioPlayer = null
    setStoreWrapper('playStatus', null)
  }

  // Начинаем воспроизведение
  playPromise = audioPlayer.play()

  if (playPromise) {
    playPromise.then(() => {
      // Automatic playback started!
      // Show playing UI.
      playPromise = null
      sendAmplitudeEvent('sound', { progress: 'success' })
    })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
          playPromise = null
          sendAmplitudeEvent('sound', { progress: 'error' })
        })
  }
  setStoreWrapper('playStatus', PLAY_STATUS_PLAYING)
}

export function pauseAudio() {
  if (playPromise) {
    return
  }
  // Проверяем, был ли создан аудиоплеер
  if (audioPlayer !== null) {
    setStoreWrapper('playStatus', null)
    // Если да, ставим воспроизведение на паузу
    audioPlayer.pause()
  }
}

export function resumeAudio() {
  // Проверяем, был ли создан аудиоплеер
  if (audioPlayer !== null) {
    // Если да, возобновляем воспроизведение
    audioPlayer.play()
  }
}
