import { callSequence, getState, setStoreWrapper } from './StoreManager'
import { sendAmplitudeEvent } from './GAEvent'

let newPostsMap = {}
const NEW_POSTS_COUNT = 'newPostsCount'

export function addNewPostsCount(uid) {
  let count = (newPostsMap[uid] || 0) + 1
  setStoreWrapper(NEW_POSTS_COUNT, (getState(NEW_POSTS_COUNT) || 0) + count)
}

export function setNewPostsCount(uid, count = 1) {
  newPostsMap[uid] = count
  setStoreWrapper(NEW_POSTS_COUNT, (getState(NEW_POSTS_COUNT) || 0) + count)
}

export function clearNewPostsCount() {
  newPostsMap = {}
  setStoreWrapper(NEW_POSTS_COUNT, null)
}

export function markAllPostsViewed() {
  let marked = false
  Object.keys(newPostsMap).map(uid => {
    marked = true
    callSequence('posts.postsWatched', { uid })
  })

  clearNewPostsCount()

  if (marked) {
    sendAmplitudeEvent('new_posts_marked', {
      openFrom: 'all'
    })
  }
}

export function markNewPostsViewed(uid) {
  if (newPostsMap[uid]) {
    callSequence('posts.postsWatched', { uid })
    setStoreWrapper(NEW_POSTS_COUNT, (getState(NEW_POSTS_COUNT) || 0) - newPostsMap[uid])

    sendAmplitudeEvent('new_posts_marked', {
      openFrom: 'top_bar'
    })

    delete newPostsMap[uid]
  }

  // let user = getUser(uid)
  // if (user && user.newPostsCount){
  //   user.newPostsCount = 0
  // }
}

export function getNewPostsPosts(uid) {
  return newPostsMap[uid] || 0
}
