import { state } from 'cerebral'

export const checkUnreadComments = ({ store, get }) => {
  const comments = get(state`auth.stuff.comments`)
  if (!comments) {
    return
  }

  store.set('chat.unreadComments', comments)
}
