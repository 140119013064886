import { getState, setStoreWrapper } from './StoreManager'
import { getPostData } from './postUtils'
import isVip from './isVip'
import { inIframe } from './inIframe'
import { isTWA } from './twa'
import { getUserAgeRating } from './ageRating/ageRatingUtil'
import { createLoginPanel } from '../components/Panels/createPanelUtil'
import { getProfilePostId, getUserCity, getUserGEOCountry } from './userUtil'
import { getLocaleValue } from './localeUil'
import { HIDDEN_COUNTRY } from './geo/getLocation'
import { getPostNew } from '../modules/posts/signals/getPost'
import { getUser, updateUser } from './userLoader'

export function getMyUid() {
  return getState('auth.uid')
}

export function getMyDonateLink() {
  return getState('auth.donateLink')
}

export function imFemale() {
  return getState('auth.female')
}

export function myProfileId() {
  return getState('auth.profileId')
}

export function setMyProfileId(profileId) {
  getPostNew(profileId, true, () => {
    const user = getUser(getMyUid())
    user.profile_post = getPostData(profileId)
    if (user.profile_post && !user.profile_post.avatar){
      if (user.storage && user.storage.profilePost && user.storage.profilePost.avatar){
        user.profile_post.avatar = user.storage.profilePost.avatar
      }
    }
    updateUser(getMyUid(), user)
    setStoreWrapper('auth.profileId', profileId)
    setStoreWrapper('auth.profile_post', getPostData(profileId))
  })
  setStoreWrapper('auth.profileId', profileId)
}

export function setFemale(female) {
  return setStoreWrapper('auth.female', female)
}

export function imVIP() {
  return isVip()
}

export function isNotificationEnable() {
  return !(!isTWA() && !inIframe() && getState('app.oneSignalInited') && getState('app.notificationsButtonMode') === 0)
}

export function imLoggedIn() {
  return getState('auth.isLoggedIn')
}

export function getMyName() {
  return getState('auth.name')
}

export function imModel() {
  return (getState('auth.model') > 0 || getUserAgeRating(getMyUid()) !== 0)
}

export function loginCheckWithPanelOpen() {
  if (!imLoggedIn()) {
    createLoginPanel()
    return false
  }
  return true
}

export function isMyLocationHidden() {
  return getState('auth.hiddenLocation')
}

export function getMyLocation() {
  const selectedCountry = getState('selectedCountry')
  let country = selectedCountry || getUserGEOCountry(getMyUid(), true)

  if (country === HIDDEN_COUNTRY) {
    return getLocaleValue(HIDDEN_COUNTRY)
  }

  if (isMyLocationHidden() && !selectedCountry) {
    return getLocaleValue(HIDDEN_COUNTRY)
  }

  const city = selectedCountry ? getState('selectedCityName') : getUserCity(getMyUid(), true)

  if (!city) {
    return country
  }

  return country + ', ' + city
}

export function getMyProfile() {
  return getProfilePostId(getMyUid())
}

export function isMyProfileSet() {
  return getProfilePostId(getMyUid()) || getState('profileSet')
}
