import { GETRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getMyUid } from '../my'
import { setStoreWrapper } from '../StoreManager'

export function getLoveLevel(uid) {
    GETRequest(getCoreUrl('/api/v1/users/love-level?from_uid=' + uid +  '&to_uid=' + getMyUid()), data => {
        setStoreWrapper('loveLevel' + uid, data.love_level)
    })
}
