import { props, state } from 'cerebral'
import { unset, wait } from 'cerebral/factories'
import panelsOnPop from './panelsOnPop'
import config from '@/config'
import { getSessionId } from '@/utils'
import { isTWA } from '@/utils/twa'
import { LEVEL_0, shopPropsType } from '@/utils/subscriptionUtil'
import openShop from './openShop'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getUserFeed, getUserPosts } from '../../posts/signals/getPosts'
import askRateApp from '@/utils/askRateApp'
import { inIframe } from '../../../utils/inIframe'
import { createSubRequirementsPanel } from '../../../components/Panels/createPanelUtil'
import { postNeedSubscription } from '../../../utils/subscriptionUtil'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { getOnlineStatusForAmplitude, getStreamStatusForAmplitude } from '../../socket/actions'
import { setStoreWrapper } from '../../../utils/StoreManager'
import { getLikeID } from '../../../components/Button/SubscriptionButton'
import { imInRoom, imOnHomeRoute } from '../../../utils/appUtil'
import { addNewMatchCounter } from '../../../utils/dating/datingNewContentUtil'
import { getFanInfo } from '../../fans/fansUtil'

const { ftCoreUrl } = config

export default [
  async ({ props, get, store, path }) => {
    // if (props.type !== LEVEL_0) {
    //   const settings = get(state`intl.settings`)
    //   let cost = 0
    //   if (props.duration === 'MONTH') {
    //     cost = settings['paidSubscriptions_' + props.type]
    //     props.typeAmplitude = props.type.toLowerCase()
    //   } else if (props.duration === 'YEAR') {
    //     cost = settings['paidSubscriptionsYEAR_' + props.type]
    //     props.typeAmplitude = props.type.toLowerCase() + '_year'
    //   } else {
    //     props.typeAmplitude = props.type.toLowerCase()
    //   }
    //   props.cost = cost
    //   if (cost > get(state`auth.coins`)) {
    //     return path.shop()
    //   }
    // } else {
      const tempUser = get(state`auth.isTempUser`)
      const pushNotEnabled = !isTWA() && !inIframe() && get(state`app.oneSignalInited`) && get(state`app.notificationsButtonMode`) === 0
      if (!props.like && tempUser && pushNotEnabled && location.host !== 'localhost:3000') {
        createSubRequirementsPanel(props.uid)
        return path.freeSubRequirements()
      }
    // }

    const subs = get(state`auth.subs`)
    subs[props.uid] = { subscriptionType: props.type, subscriptionDate: Date.now() }
    // if (props.type !== LEVEL_0) {
    //   if (props.duration === 'MONTH') {
    //     subs[props.uid].subscriptionDateEnd = Date.now() + 30 * 24 * 60 * 60 * 1000
    //   } else if (props.duration === 'YEAR') {
    //     subs[props.uid].subscriptionDateEnd = Date.now() + 365 * 24 * 60 * 60 * 1000
    //   }
    // } else {
      setStoreWrapper(getLikeID(props.uid), true)
    // }
    store.set('auth.subs', subs)

    //если юзер мой фанат, то добавим счетчик матчей
    if (getFanInfo(props.uid)) {
      addNewMatchCounter()
    }

    await fetch(`${ftCoreUrl}/api/v2/subscriptions/subscribe`, {
      method: 'POST',
      body: new URLSearchParams({
        sid: getSessionId(),
        model: props.uid,
        subscriptionType: props.type,
        duration: props.duration
      })
    })
    return path.ok()
  }, {
    shop: [
      ({ props, get }) => ({
        message: get(state`intl.content.coins_subscription`),
        //reason: props.type + '-subscribe-click',
        reason: shopPropsType(props.type, props.duration)
      }),
      openShop
    ],
    freeSubRequirements: [],
    ok: [
      panelsOnPop,
      ({ get, store }) => {
        if (get(state`posts.selectedPostId`) && postNeedSubscription(get(state`posts.selectedPostId`))) {
          store.unset(`posts.postsData.${get(state`posts.selectedPostId`)}`)
        }
        let count = get(state`auth.subsCount`) || 0
        count++
        store.set(state`auth.subsCount`, count)
      },
      unset(state`posts.allUserPostsLoaded.${props`uid`}`),
      unset(state`posts.userPosts.${props`uid`}`),
      wait(500),
      getUserPosts,
      //onLoadUsers,
      () => {
        askRateApp('subscription')
      },
      ({ props }) => {
        sendAmplitudeEvent('subscriptions', {
          action: 'subscribe',
          type: (props.typeAmplitude || LEVEL_0).toLowerCase(),
          counterparty_id: props.uid,
          online: getOnlineStatusForAmplitude(props.uid),
          stream: getStreamStatusForAmplitude(props.uid),
          home: imOnHomeRoute(),
          inRoom: imInRoom(),
          age_rating: getUserAgeRating(props.uid),
          feed: getUserFeed(),
          sent_coins: props.cost || 0
        })
      },
    ]
  }
]
