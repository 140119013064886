import { GETRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getMyUid } from '../my'
import { loadUsersAndProfiles } from '../userLoader'
import { getState, setStoreWrapper } from '../StoreManager'

export function loadUidsNearMe(){
  GETRequest(getCoreUrl('/api/v1/users/near'), (data)=>{
    //запросим юзеров с профилями
    loadUsersAndProfiles(data.users, ()=>{
      console.error('/api/v1/users/near?uid')
      if (!data.users || !data.users.length) {
        return
      }
      const topPosts = getState('topPosts', []).concat(data.users)
      // topPosts = topPosts.concat(data.users)
      setStoreWrapper('posts.loadingPostsStatus', 'loaded')
      setStoreWrapper('posts.topPosts', topPosts)
    })
  }, ()=> {
    console.error('/api/v1/users/near?uid')
  })
}
