import config from '@/config'
import { getState, setStoreWrapper } from '../../utils/StoreManager'
import { getSessionId } from '../../utils'
import { loadUsers } from '@/utils/userLoader'
import { getProfilePostId } from '../../utils/userUtil'
import { checkMySubscription } from '../../utils/subscriptionUtil'
import { updateFansDate, updateMatchDate } from '../../utils/updateWatchedDate'
import { addFollowerCount, addNewMatchCounter } from '../../utils/dating/datingNewContentUtil'

const { ftCoreUrl } = config

const FANS_INFO = 'fansInfo'
const FANS_LOADING_STATUS = 'fansLoadingStatus'

let errorTime

export function loadFansInfo(uids) {
  if (notLoadExtraFans || !uids || !uids.split) {
    return
  }
  const fansLoadingStatus = getState(FANS_LOADING_STATUS) || {}
  const fansInfo = getFansInfoSource()
  const uidArray = uids.split(',')
  const needLoadUids = []
  uidArray.forEach(uid => {
    if (!fansLoadingStatus[uid] && !fansInfo[uid]) {
      fansLoadingStatus[uid] = true
      needLoadUids.push(uid)
    }
  })

  //если не осталось ничего незагруженного и не грузящегося то ничего не делаем
  if (!needLoadUids.length || !getSessionId() || (errorTime && errorTime - Date.now() < 5000)) {
    return
  }

  setStoreWrapper(FANS_LOADING_STATUS, fansLoadingStatus)

  try {
    fetch(`${ftCoreUrl}/api/v1/subscriptions/followers?followers=${needLoadUids.join(',')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    }).then(async fResponse => {
      const fansInfos = await fResponse.json()
      const currentFansInfo = getFansInfoSource()
      let needUpdate = false
      try {
        Object.keys(fansInfos.followers).map(uid => {
          currentFansInfo[uid] = fansInfos.followers[uid]
          needUpdate = true
        })

        if (needUpdate) {
          setStoreWrapper(FANS_INFO, currentFansInfo)
        }
        console.log('@@@ load loadFanInfo test')
      } catch (e) {
        errorTime = Date.now()
        console.log('@@@ load loadFanInfo error', e)
      }
    }).catch(e => {
        console.warn('loadFanInfo catch error', e)
        errorTime = Date.now()
      }
    )
  } catch (e) {
    errorTime = Date.now()
    console.log('@@@ loadFanInfo error', e)
  }
}

let stackedFans
let uidsInStack = []

export function getFanInfo(uid) {
  const fansInfo = getFansInfoSource()
  if (!fansInfo[uid]) {
    if (!stackedFans) {
      stackedFans = setTimeout(()=> {
        loadFansInfo(uidsInStack.join(','))
        stackedFans = null
        uidsInStack = []
      }, 200)
    } else {
      if (!uidsInStack.includes(uid)) {
        uidsInStack.push(uid)
      }
    }
  }

  return fansInfo[uid]
}

export function addFan(uid, fanInfo) {
  const fansInfo = getFansInfoSource()
  if (fanInfo && !fanInfo.subscriptionDate){
    fanInfo.subscriptionDate = Date.now()
  }
  fansInfo[uid] = fanInfo
  setStoreWrapper(FANS_INFO, fansInfo)
  if (checkMySubscription(uid)) {
    addNewMatchCounter()
  }
  addFollowerCount()
}

export function dellFan(uid) {
  const fansInfo = getFansInfoSource()
  delete fansInfo[uid]
  setStoreWrapper(FANS_INFO, fansInfo)
}

function getFansInfoSource() {
  return getState(FANS_INFO) || {}
}

export function setNewFansViewed() {
  updateFansDate()
  // setStoreWrapper('auth.newFollowersCount', 0)
}

export function setNewMatchViewed() {
  updateMatchDate()
  setStoreWrapper('auth.newMatchCount', 0)
}

let notLoadExtraFans

export function addFansOnAuth(fans) {
  if (fans && fans.length) {
    let fansInfo = getFansInfoSource()
    fans.forEach(uid => fansInfo[uid] = true)
    setStoreWrapper(FANS_INFO, fansInfo)
  }

  notLoadExtraFans = !fans || fans.length < 50
}
