import { GETRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getState, setStoreWrapper } from '../StoreManager'
import { checkSelectedFeed } from '../../modules/filters/actions/feedUtil'
import { TYPE_MATCH } from '../../modules/posts/signals/getPosts'

export function loadMyNewContentCounters(){
  GETRequest(getCoreUrl('/api/v1/subscriptions/updates-counts'), (data)=> {
    setStoreWrapper('auth.newFollowersCount', data.new_followers_no_match_count)
    setStoreWrapper('auth.newMatchCount', data.new_match_count)
    // console.log(data)
  })
}

export function reduceFansCount(){
  const count = getState('auth.newFollowersCount')
  if (count > 0) {
    setStoreWrapper('auth.newFollowersCount', count - 1)
  }
}

export function addNewMatchCounter(){
  if (checkSelectedFeed(TYPE_MATCH)) {
    return
  }
  setStoreWrapper('auth.newMatchCount', getState('auth.newMatchCount', 0) + 1)
}

export function addFollowerCount(){
  setStoreWrapper('auth.newFollowersCount', getState('auth.newFollowersCount', 0) + 1)
}
