import { push, shift, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { send } from '../../socket/actions'
import { MAX_SAVED_VIEWS } from '../../../utils/post/postViewUtil'

export default [
  ({ props }) => ({
    postId: Number(props.postId)
  }),
  when(state`posts.viewedPhotos`, props`postId`, (viewedPhotos, postId) => !viewedPhotos.includes(postId)), {
    true: [
      ({ props }) => ({
        params: { postId: props.postId },
        method: 'postView'
      }),
      send,
      push(state`posts.viewedPhotos`, props`postId`),
      when(state`posts.viewedPhotos`, viewedPhotos => viewedPhotos.length > MAX_SAVED_VIEWS), {
        true: [
          shift(state`posts.viewedPhotos`)
        ],
        false: []
      },
    ],
    false: []
  }
]
