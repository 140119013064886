import { state } from 'cerebral'
import config from '@/config'
import controller from '@/controller'
import { getSessionId } from '@/utils'

export default [
  async ({ store, get, props }) => {
    const {
      vbApiUrl
    } = config
    const {
      descErrorExchange,
      usdBalance,
    } = props

    const showSnackbar = controller.getSequence('app.showSnackbar')
    const currencyMin = get(state`intl.settings.vb_settings_min_currency_exchange`)
    const usdExchangeRate = get(state`intl.settings.coinsToMoney`)
    //const currencyNeed = currencyMin - usdBalance

    let res = ''
    if (usdBalance >= currencyMin) {
      try {
        const fd = new URLSearchParams()
        fd.append('sid', getSessionId())
        const response = await fetch(`${vbApiUrl}/vb2/balance/convert`, {
          method: 'POST',
          body: fd
        })
        res = await response.json()
      } catch (e) {
        console.log(e)
      }
      if (res.error) {
        showSnackbar({
          text: get(state`intl.content.charge_error`),
          ms: 1500,
          type: 'error'
        })
      } else {
        const usdBalance = res.receivedCoins / usdExchangeRate

        store.set('auth.receivedCoins', res.receivedCoins)
        store.set('auth.purchasedCoins', res.purchasedCoins)
        store.set('auth.coins', res.purchasedCoins)
        store.set('auth.usdBalance', Math.trunc(usdBalance * 100) / 100)

        showSnackbar({
          text: get(state`intl.content.purchase_completed`),
          ms: 1500,
          type: 'success'
        })
      }
    } else {
      showSnackbar({
        text: descErrorExchange,
        ms: 1500,
        type: 'blacklist'
      })
    }
  }
]
