import controller from '@/controller'
import { isDesktopSkin } from '@/utils/checks'

export default {
  state: {
    media: {
      desktop: isDesktopSkin()
    },
    window: {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }
}

const updateModule = () => {
  controller.getSequence('app.onResize')()
}

window.addEventListener('resize', updateModule)
