import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { deleteDialogMessage, getDialogById } from '../../../utils/chat/dialogUtil'

export default [
  ({ props }) => ({
    params: {
      postId: props.post,
      fileId: props.fileId,
      timestamp: parseInt(props.postId, 36) / 1000
    },
    method: 'deleteComment'
  }),
  send,
  ({ store, get, props }) => {
    const postComments = getDialogById(props.dialog_id)
    const newPostComments = postComments.messages
    const some = newPostComments.filter(
      message => {
        return props.postId !== message.message_id
      }
    )
    postComments.messages = some
    const allDialogs = get(state`chat.dialogs`)
    store.set('chat.dialogs', allDialogs)
    deleteDialogMessage(props.postId)
  }
]
