import { state } from 'cerebral'

export const checkIfTranslationIsPaidOrPrivate = ({ get, path }) => {
  const route = get(state`app.route`)
  const roomId = get(state`app.roomId`)
  const myUid = get(state`auth.uid`)
  const paidTranslations = get(state`app.paidRooms`)
  const coins = get(state`auth.coins`)
  if (route === 'room' && roomId !== myUid) {
    if (paidTranslations[roomId] && coins < paidTranslations[roomId]) return path.paidNoCoins()
    const privateData = get(state`posters.privates.${roomId}`)
    if (privateData && myUid !== privateData.model && myUid !== privateData.viewer) {
      return path.modelInPrivateWithOtherUser()
    }
  }

  return path.doNothing()
}
