import { props, state } from 'cerebral'
import { set } from 'cerebral/factories'
import config from '@/config'
import { getSessionId } from '@/utils'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { CHAT_PERMISSION, COMMENT_PERMISSION, LIVE_PERMISSION, POST_PERMISSION } from '@/utils/subscriptionUtil'
import { saveMetadata } from '../../room/signals/sendCreateBroadcast'

const { vbApiUrl } = config

export default [
  ({ path, props }) => {
    if (typeof props.id === 'number') {
      return path.postEdit()
    }
    if (props.id === POST_PERMISSION) {
      return path.newPost()
    }
    return path.settings()
  }, {
    postEdit: [
      set(state`posts.postsData.${props`id`}.subscriptionType`, props`value`),
    ],
    newPost: [
      set(state`upload.subscriptionType`, props`value`),
    ],
    settings: [
      ({ props, get, store }) => {
        const subSettings = get(state`auth.subscriptionSettings`)
        if (!props.value) {
          if (props.id === CHAT_PERMISSION) {
            let metadata = get(state`auth.storage.metadata`) || {}
            store.set('auth.storage.metadata', metadata)
            saveMetadata({ get })
          }
          delete subSettings[props.id]
        } else {
          subSettings[props.id] = props.value
        }

        store.set('auth.subscriptionSettings', subSettings)

        fetch(`${vbApiUrl}/vb2/subscriptions/settings`, {
          method: 'POST',
          body: new URLSearchParams({
            sid: getSessionId(),
            settings: JSON.stringify(subSettings)
          })
        }).catch(e => console.warn('catch error', e))

        let settingType = 'unknown'
        switch (props.id) {
          case LIVE_PERMISSION:
            settingType = 'live'
            break
          case CHAT_PERMISSION:
            settingType = 'chat'
            break
          case COMMENT_PERMISSION:
            settingType = 'comments'
            break
        }

        sendAmplitudeEvent('subscriptions', {
          action: 'settings',
          settings: settingType,
          type: (props.value || 'all').toLowerCase(),
          sent_coins: 0
        })
      },
    ]
  },
  // panelsOnPop,
]
