import { getChatDraft } from '../../../utils/inputUtil'

export default ({ props, store, get, path }) => {
  const currentText = getChatDraft(props.chatKey)
  if (props.key === 'Enter' && currentText.trim().length > 0) {
    return path.sendChatMessage()
  }
  if (props.key === 'Backspace' && currentText === '') {
    store.set('chat.toId', '')
  }
  return path.doNothing()
}
