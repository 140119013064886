import { imVIP, isMyProfileSet } from '../my'
import { datingNeedProfile } from '../../modules/posts/signals/postLike'
import { getState } from '../StoreManager'
import { isMe } from '../checks'
import { CHAT_PERMISSION, needSubscription } from '../subscriptionUtil'
import { createGetVipPanel } from '../../components/Panels/createPanelUtil'
import { getAppConfig } from '../appConfigUtil'
import { isTWA } from '../twa'
import { MESSAGE_TYPE_SYSTEM } from '../manyConst'
import { SPAMMER_UID } from '../../modules/chat/actions'
import { QUIZ } from '../../modules/buffs'
import { isChicken, isRabbit } from '../cardGame/cardGameUtil'
import { getLocaleValue } from '../localeUil'

export const CHAT_ALL = 'all'
export const CHAT_VIP = 'vip'
export const CHAT_DISABLE = 'disable'
export const CHAT_PROFILE = 'profile'

export function isChatAvailable(openPanel) {
  const roomId = getState('app.roomId')
  if (isMe(roomId)) {
    return true
  }

  //настройки конфига перекрывают настройки юзера
  const appConfig = getAppConfig()
  if (appConfig.chat_mode === CHAT_DISABLE) {
    return false
  }

  //настройки конфига перекрывают настройки юзера
  if (appConfig.chat_mode === CHAT_VIP) {
    if (imVIP()) {
      return true
    }
    if (openPanel) {
      createGetVipPanel()
    }
    return false
  }

  if (appConfig.chat_mode === CHAT_PROFILE && !needSubscription(roomId, CHAT_PERMISSION, true)) {
    if (imVIP() || isMyProfileSet()) {
      return true
    }
    if (openPanel) {
      datingNeedProfile('chat_restricted_for_profile')
    }
    return false
  }

  if (needSubscription(roomId, CHAT_PERMISSION)) {
    if (openPanel) {
      createGetVipPanel()
    }
    return false
  }
  return true
}

export function isTextMessage(msgData) {
  if (!msgData) {
    return false
  }

  if (msgData.gift || msgData.set || msgData.sticker || msgData.card || msgData.type === MESSAGE_TYPE_SYSTEM || msgData.type === QUIZ) {
    return false
  }
  return true
}

export function getChatMessageText(msgData) {
  if (!msgData) {
    return null
  }

  //сообщения петушка заменяются на кудахтание
  if (msgData && msgData.metadata && msgData.metadata.textReplaced) {
    return getLocaleValue(msgData.metadata.textReplaced)
  }
  if (isChicken(msgData.fromId)) {
    return getLocaleValue('bad_buff_word_replace_4')
  }

  if (isRabbit(msgData.fromId)) {
    return getLocaleValue('bad_buff_word_replace_12')
  }

  if (isMe(msgData.fromId) || msgData.fromId === SPAMMER_UID) {
    return msgData.text || msgData.messageOriginal
  }

  let text
  const lang = getState('intl.lang')
  if (msgData.translations &&
    msgData.autoTranslation &&
    !isMe(msgData.fromId)
  ) {
    const translate = isTWA() ? msgData.translationsApp : msgData.translationsWeb
    if (translate[lang]) {
      text = translate[lang]
    }
  }
  if (!text) {
    text = isTWA() ? msgData.messageApp : msgData.messageWeb
  }
  return text || null
}
