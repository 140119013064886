import { set, wait } from 'cerebral/factories'
import { props, state } from 'cerebral'
import panelsOnPop from '../../app/signals/panelsOnPop'
import loadCategoryTags from './loadCategoryTags'

export default [
  set(state`upload.postKind`, props`postKind`),
  wait(100),
  panelsOnPop,
  loadCategoryTags
]
