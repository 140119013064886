import { DELETERequest } from './requestWrapper'
import config from '@/config'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { getUsersURL } from './apiURLUtil'
import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import { getUser } from '../userLoader'
import { closeAllPanels } from '../../components/Panels/createPanelUtil'

const { dialogsUrl } = config

export function deleteAllDialogs(uid) {
  DELETERequest(`${dialogsUrl}/api/v1/dialogs/delete-by-user/` + uid, {}, () => {
    setStoreWrapper('chat.dialogsLoaded', false)
    setStoreWrapper('chat.dialogs', null)
    setStoreWrapper('chat.dialogsPage', 0)
    closeAllPanels()
  })
}

export function deleteAllPostsAndProfile(uid) {
  DELETERequest(getUsersURL('delete-posts/' + uid), {}, () => {
    setStoreWrapper('posts.userPosts', {})
    setStoreWrapper('updateDairy', uid)
    const user = getUser(uid)
    if (user && user.storage) {
      delete user.storage.profilePost
    }
    delete user.profile_post
    const users = getState('app.users') || {}
    users[uid] = user
    setStoreWrapper('app.users', users)
    closeAllPanels()
  })
}

export function deleteUserImmediately(uid) {
  DELETERequest(getUsersURL('delete/' + uid), {}, () => {
    //хз что-то нужно еще делать или нетdeleteAllDialogs
    callSequence('auth.logOut')
  })
}
