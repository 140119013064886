import { getState, setStoreWrapper } from '../StoreManager'
import { goLoadCategoryTags } from '../../modules/upload/signals/loadCategoryTags'

export async function loadProfileTags(cb) {
  if (!getState('loadedCatsProfile')) {
    // setStoreWrapper('loadedCatsProfile', true)
    goLoadCategoryTags('postProfile', () => {
      setStoreWrapper('loadedCatsProfile', true)
      if (cb) {
        cb()
      }
    })
  } else {
    if (cb) {
      cb()
    }
  }
}

