import { showSnackbar } from '../../app/factories'
import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { banMsg } from '../actions'
import banRoomChat from './banRoomChat'

export default [
  banMsg,
  showSnackbar(state`intl.content.claim_success`, 3000),
  ({ props, get }) => ({
    method: 'messageComplaint',
    params: {
      messageId: props.messageId + '',
      roomId: get(state`app.roomId`)
    },
  }),
  send,
  banRoomChat
]
