import { set, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import routeTo from './routeTo'
import { prepareRoute } from '../actions/prepareRoute'
import { send } from '../../socket/actions'

export default [
  set(state`shop.openReason`, props`reason`),
  prepareRoute,
  routeTo,
  when(state`room.proBroadcast`, state`app.route`,
    (proBroadcast, route) => proBroadcast && route !== 'room'), {
    true: [
      set(state`room.proBroadcast`, false),
      set(state`room.streamInRoom`, null),
      () => ({ method: 'endBroadcast' }),
      send,
    ],
    false: []
  }
]
