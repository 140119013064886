import { getState, setStoreWrapper } from './StoreManager'
import { COIN_500_ICON } from './stringUtils'
import { isCDNDisable } from './appUtil'
import { PROXY_PHOTOS_URL } from './getPreviewUrl'
import { GETRequest, POSTRequest } from './api/requestWrapper'
import { getCoreUrl } from './api/apiURLUtil'
import { isTWA } from './twa'
import { getHost } from './mobileAppUrls'

export function getSettings(key, defaultValue) {
  return (getState('intl.settings') || {})[key] || defaultValue
}

export function getSettingsInt(key) {
  return getSettings(key) || 0
}

export function getSettingsCost(key) {
  return COIN_500_ICON + getSettingsInt(key)
}

export function getPostPhotoUrl(fileName) {
  let settingsUrl = getSettings('posts_media_urls')
  if (!settingsUrl) {
    return null
  }
  if (isProdEnv) {
    return (isCDNDisable() ? PROXY_PHOTOS_URL : settingsUrl.photos_url_prod) + fileName
  }
  return (isCDNDisable() ? PROXY_PHOTOS_URL : settingsUrl.photos_url_dev) + fileName
}

export function getPostVideoUrl(fileName) {
  let settingsUrl = getSettings('posts_media_urls')
  if (!settingsUrl) {
    return null
  }
  if (isProdEnv) {
    return settingsUrl.videos_url_prod + fileName
  }
  return settingsUrl.videos_url_dev + fileName
}

export function getMySettings() {
  if (getState('mySettings')) {
    return
  }
  GETRequest(getCoreUrl('/api/v1/users/settings'), (response) => {
    setStoreWrapper('mySettings', response.settings)
  })
}

export function updateMySettings(key, value) {
  const mySettings = getState('mySettings') || {}
  mySettings[key] = value
  setStoreWrapper('mySettings', mySettings)
  POSTRequest(getCoreUrl('/api/v1/users/settings'), { settings: mySettings })
}

export function checkMySettings(key) {
  return (getState('mySettings') || {})[key]
}

export function isGetMoneyMode() {
  return !isTWA() && getHost() !== 'ulive.chat'
}
