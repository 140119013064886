import { set, when } from 'cerebral/factories'
import { state } from 'cerebral'
import { getLangCode, getLocaleRequest } from '../actions'
import { loadBroadcastNotifications } from '../../chat/actions'

const loadLocale = [
  ({ get, props, path }) => {
    const { locale } = props
    if (typeof locale !== 'string') return path.doNothing({ reason: 'locale not string.' })
    if (locale.length !== 2) return path.doNothing({ reason: 'length of locale is not 2' })
    const history = get(state`app.routesHistory`)
    const lang = get(state`intl.lang`)
    if (locale !== lang || history.length < 2) {
      return path.handleLocale()
    } else {
      return path.doNothing({ reason: 'locale equal user lang or history is bigger or equal 2' })
    }
  }, {
    doNothing: [
      // ({ props }) => console.log('locale doesnt handle, reason: ' + props.reason),
    ],
    handleLocale: [
      getLangCode,
      () => ({ useCache: true }),
      getLocaleRequest, {
        success: [
          // loadBroadcastNotifications
        ],
      },
      set(state`chat.broadcastDialogs`, {}),
      loadBroadcastNotifications
    ]
  }
]

export default [
  when(state`app.routesHistory`, history => history.length === 0),
  {
    true: [
      ({ props, path, get }) => {
        const userLang = get(state`auth.lang`)
        if (!userLang) return path.loadLocale()
        if (userLang !== props.locale) return path.redirectToUserLang({ userLang })
        return path.loadLocale()
      }, {
        loadLocale: loadLocale,
        redirectToUserLang: [
          ({ router, props }) => {
            router.redirect(`/${props.userLang}${props.url}${location.search}`)
          }
        ]
      }
    ],
    false: []
  }
]
