import sendChatMessage from '../actions/sendChatMessage'
import onNewMessage from '../actions/onNewMessage'
import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { CHAT_PERMISSION, needSubscription, PRIVATE_MSG_PERMISSION } from '@/utils/subscriptionUtil'
import { subscriptionPanel } from '../../../components/Panels/types'
import { createPanel } from '../../app/actions'
import { LEVEL_VIP } from '../../../utils/subscriptionUtil'
import { sendDialogMessage } from '../actions'
import { SUPPORT_UID } from '../../app/signals/openSupport'
import { isPaidDialogMessage } from '../../../utils/chat/dialogUtil'
import { canBuyCheck } from '../../../utils/checkBalanceUtil'
import { imAdmin } from '../../../utils/adminUtil'
import { getRoomOwnerUid } from '../../../utils/navigation/navigationUtil'
import { checkEnergy, ENERGY_DIALOG, getEnergyDialog } from '../../../utils/energy/energyUtil'
import { createGetVipPanel } from '../../../components/Panels/createPanelUtil'

export default [
  ({ path, get }) => {
    let permission = null
    let toId = null
    let isDialog = false
    const panels = get(state`app.panels`)
    if (panels.length > 0 && panels[panels.length - 1].type === 'dialog') {
      isDialog = true
      toId = get(state`app.dialogUserId`)
      permission = needSubscription(toId, PRIVATE_MSG_PERMISSION)
    } else {
      toId = getRoomOwnerUid(get(state`chat.chatRoomId`))
      permission = needSubscription(toId, CHAT_PERMISSION)
    }
    if (permission && get(state`auth.uid`) !== SUPPORT_UID && !imAdmin()) {
      if (permission === LEVEL_VIP) {
        createGetVipPanel('vip_only_dialog')
        return path.doNothing()
      }
      return path.needPermission(
        subscriptionPanel(toId, permission)
      )
    }
    if (isDialog) {
      if (isPaidDialogMessage(toId)) {
        let settings = get(state`intl.settings`)
        if (!canBuyCheck(settings.dialogMessageCost, 'paidDialogMessage')) {
          return path.doNothing()
        }
      }
      if (!checkEnergy(ENERGY_DIALOG, getEnergyDialog(toId))) {
        return path.doNothing()
      }
      return path.sendDialogMsg({ uid: toId })
    }
    return path.sendMsg()
  }, {
    needPermission: [
      createPanel,
    ],
    sendDialogMsg: [
      sendDialogMessage,
    ],
    doNothing: [],
    sendMsg: [
      sendChatMessage,
      send,
      //показываем cообщение cразу, не дожидаяcь cервера
      context => {
        if (!context.props.msgData) {
          return
        }
        onNewMessage(context)
      }
    ],
  }
]
