import { send } from '../../socket/actions'
import { when } from 'cerebral/factories'
import { state } from 'cerebral'

export default [
  when(state`chat.chatRoomId`, roomId => roomId && roomId.length > 0 && roomId.length < 6), {
    true: [
      ({ store, get, props }) => {
        store.set('overlayScrolled', false)
        store.set('smoothScroll', false)
      },
      ({ store, get }) => ({
        params: {
          roomId: get(state`chat.chatRoomId`)
        },
        method: 'loadChatHistory'
      }),
      send
    ],
    false: []
  }
]
