import { isDesktopSkin, isMobileSkin } from './checks'

const MAIN_VIDEO_MIN_W = 380
const MAIN_VIDEO_MIN_H = 380

const MAIN_VIDEO_MAX_W = 1980
const MAIN_VIDEO_MAX_H = 1080

const WEB_MIN_CHAT_W = 300

const WEB_MAX_BORDER = 100

export const MOBILE_LOCAL_VIDEO_H_MIN = 0.65
export const MOBILE_LOCAL_VIDEO_H_MAX = 1

//проверим 40%
export let MOBILE_MAX_LOCAL_VIDEO_H = 0.65

export function switchSize(fullScreen) {
  MOBILE_MAX_LOCAL_VIDEO_H = fullScreen ? MOBILE_LOCAL_VIDEO_H_MAX : MOBILE_LOCAL_VIDEO_H_MIN
}

export function getMainVideoContainerSize(videoW, videoH, availableW, availableH, isMobile) {
  //если размеров реального видео нет - используем дефолты
  videoW = videoW || MAIN_VIDEO_MIN_W
  videoH = videoH || MAIN_VIDEO_MIN_H

  if (isDesktopSkin()) {
    availableW = availableW - WEB_MIN_CHAT_W
  }

  //если доступная область больше чем максимально возможная, то используем максимальную
  // availableW = Math.min(availableW, MAIN_VIDEO_MAX_W)
  // availableH = Math.min(availableH, MAIN_VIDEO_MAX_H)

  const scaleW = availableW / videoW
  const scaleH = availableH / videoH

  //для начала впишем видео в доступную область с сохренением пропорций
  let scale// = Math.min(scaleW, scaleH)

  let wrapW
  let wrapH
  let forceCover
  let fit// = 'contain'
  if (scaleW > scaleH) {
    wrapW = Math.round(videoW * scaleH)
    wrapH = Math.round(videoH * scaleH)

    if (isMobile) {
      forceCover = false
    } else {
      forceCover = availableW > wrapW
    }
    //если отсаются поля меньше WEB_MAX_BORDER пикселей, то слелаем апскейл
    if ((availableW - wrapW) < WEB_MAX_BORDER || forceCover) {
      scale = scaleW
      fit = 'cover'
    } else {
      scale = scaleH
      fit = 'contain'
    }
  } else {
    wrapW = Math.round(videoW * scaleW)
    wrapH = Math.round(videoH * scaleW)

    if (isMobile) {
      forceCover = availableH > wrapH
    } else {
      forceCover = availableW > wrapW
    }
    //если отсаются поля меньше WEB_MAX_BORDER пикселей, то слелаем апскейл
    if ((availableH - wrapH) < WEB_MAX_BORDER || forceCover) {
      scale = scaleH
      fit = 'cover'
    } else {
      scale = scaleW
      fit = 'contain'
    }
  }

  if (isMobileSkin()) {
    return {
      w: Math.round(videoW * scale) + 'px',
      h: Math.round(videoH * scale) + 'px',
      wrapW: wrapW + 'px',
      wrapH: wrapH + 'px',
      fit: fit
    }
  }

  return {
    w: Math.round(videoW * scale) + 'px',
    h: Math.round(videoH * scale) + 'px',
    wrapW: wrapW + 'px',
    wrapH: wrapH + 'px',
    fit: fit
  }
}

export function getSecondVideoContainerSize(videoW, videoH, availableW, availableH) {
  //если размеров реального видео нет - используем дефолты
  videoW = videoW || MAIN_VIDEO_MIN_W
  videoH = videoH || MAIN_VIDEO_MIN_H

  //если доступная область больше чем максимально возможная, то используем максимальную
  availableW = Math.min(availableW, MAIN_VIDEO_MAX_W)
  availableH = Math.min(availableH, MAIN_VIDEO_MAX_H)

  const scaleW = availableW / videoW
  const scaleH = availableH / videoH

  //для начала впишем видео в доступную область с сохренением пропорций
  let scale = Math.max(scaleW, scaleH)

  const wrapW = Math.round(videoW * scale)
  const wrapH = Math.round(videoH * scale)

  // //если отсаются поля меньше WEB_MAX_BORDER пикселей, то слелаем апскейл
  // if (((availableW - wrapW) < WEB_MAX_BORDER) && ((availableH - wrapH) < WEB_MAX_BORDER)){
  //   scale = Math.max(scaleW, scaleH)
  // }

  if (isMobileSkin()) {
    return {
      w: Math.round(videoW * scale) + 'px',
      h: Math.round(videoH * scale) + 'px',
      wrapW: wrapW + 'px',
      wrapH: wrapH + 'px',
    }
  }

  return {
    w: Math.round(videoW * scale) + 'px',
    h: Math.round(videoH * scale) + 'px',
    wrapW: wrapW + 'px',
    wrapH: wrapH + 'px',
  }
}
