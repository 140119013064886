import { state } from 'cerebral'
import { getCookie, setSessionId, wait } from '@/utils'
import config from '@/config'
import { isIOSPWA } from '../../../utils/api/PWAUtil'

const { httpApiUrl } = config

export const requestToken = async ({ store, get, path }) => {
  try {
    const start = Date.now()

    const partnerId = get(state`auth.partnerId`)
    const fromParam = get(state`auth.fromParam`) || getCookie('mrtigra.refer')

    let referrer = partnerId
      ? encodeURI(`rid=${partnerId}`)
      : fromParam
        ? encodeURI(`rid=${fromParam}`)
        : get(state`auth.mrtigraRefer`)

    if (!referrer && get(state`app.route`) === 'post') {
      const selectedPostId = get(state`posts.selectedPostId`)
      let postData = null
      let waitTime = 0
      while (!postData && waitTime < 2000) {
        postData = get(state`posts.postsData.${selectedPostId}`)
        await wait(100)
        waitTime += 100
      }
      if (postData && postData.uid) {
        referrer = postData.uid
      }
    }
    let params = { access_token: get(state`auth.token`) }
    const appId = get(state`app.appId`)
    if (appId) {
      params.app_id = appId + (isIOSPWA() ? '-ios' : '-and')
    } else {
      params.domain = location.host
    }
    params.locale = get(state`intl.lang`)
    params = referrer ? { ...params, referrer } : params
    const response = await fetch(httpApiUrl + '/oauth/gu?' + new URLSearchParams(params).toString())
    const { result } = await response.json()
    const { session_id, userid } = result

    setSessionId(session_id)
    store.set('auth.uid', userid)
    // uploadOneSignalId()

    const end = Date.now()
    window.oauth_time = end - start
    return path.success()
  } catch (error) {
    return path.error()
  }
}
