import { getState } from './StoreManager'
import { getRoomOwnerUid } from './navigation/navigationUtil'

export function imOnHomeRoute() {
  return getState('app.route') === 'home'
}

export function imInRoom(uid) {
  return uid ? getRoomOwnerUid(getState('app.roomId')) === uid : Boolean(getState('app.roomId'))
}

export function isCDNDisable() {
  // return true
  return getState('cdnDisable')
}

export function getStatusBarH() {
  return getState('statusBarH') || 0
}
