import { getAppConfig, isNoCyber } from '../appConfigUtil'
import { getSettings } from '../settingsUtil'
import { loadUsers, loadUsersAndProfiles } from '../userLoader'
import { setStoreWrapper } from '../StoreManager'
import { getProfilePostId } from '../userUtil'
import { getPostData } from '../postUtils'
import { clearAllPosters } from '../posters/clearAllPosters'
import { checkSelectedFeed } from '../../modules/filters/actions/feedUtil'
import { TYPE_CYBER, TYPE_LIVE_ONLY } from '../../modules/posts/signals/getPosts'

function getAllCyber() {
  if (isNoCyber()) {
    return []
  }
  return getSettings('ai_users', [])
}

export function setCyberPosters(){
  const uids = getAllCyber()
  loadUsersAndProfiles(uids, ()=> {
    const posts = []
    uids.forEach(uid => {
      const pid = getProfilePostId(uid)
      if (pid) {
        posts.push(pid)
      }
    })
    if (checkSelectedFeed(TYPE_CYBER) || checkSelectedFeed(TYPE_LIVE_ONLY)){
      setStoreWrapper('posts.topPosts', posts)
      setStoreWrapper('posts.loadingPostsStatus', 'loaded_all')
    }
  })
}

export function isCyber(uid) {
  return getAllCyber().includes(uid)
}
