import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import { getSessionId } from '@/utils'
import { hideBadUrlsInDialog } from './checkChatMessage'
import { addUnreadDirectMessage } from '../../../utils/chat/dialogUtil'

const { dialogsUrl } = config

export const loadDialogsAction = async ({ store, get }) => {
  if (get(state`chat.dialogsLoaded`)) return

  const dialogsPage = get(state`chat.dialogsPage`) + 1

  let result
  try {
    const response = await fetch(`${dialogsUrl}/api/v1/dialogs?page=${dialogsPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    if (!response.ok) return
    result = await response.json()
  } catch (e) {
    return
  }
  const dialogsData = result.dialogs
  dialogsData.forEach(d => {
    d.message = hideBadUrlsInDialog(d.message)
  })

  if (dialogsData.length === 0) {
    store.set('chat.dialogsLoaded', true)
    // return
  }

  const usersToLoad = dialogsData.map(dialog => {
    if (dialog.unread_message_count) {
      addUnreadDirectMessage(dialog.dialog_id, dialog.unread_message_count)
    }
    return dialog.users_in_dialog
  }).flat(1)

  loadUsers(
    usersToLoad,
    () => {
      const oldDialogs = (get(state`chat.dialogs`) || []).map(d => {
        const newD = dialogsData.find(od => od.dialog_id === d.dialog_id)
        return newD ? { ...d, ...newD } : d
      })
      const allDialogs = [
        ...oldDialogs,
        ...dialogsData.filter(
          d => !oldDialogs.find(od => od.dialog_id === d.dialog_id)
        )
      ]
      store.set('chat.dialogs', allDialogs)
    }
  )
  store.set('chat.dialogsPage', dialogsPage)
}
