import { state } from 'cerebral'
import config from '@/config'
import { getSessionId } from '@/utils'
import controller from '@/controller'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { sendAmplitudeEvent } from '@/utils/GAEvent'

export default [
  () => ({ hideAll: true }),
  panelsOnPop,
  async ({ get, props, store }) => {
    const {
      vbApiUrl
    } = config

    let res = ''
    let aParams = ''
    aParams = new URLSearchParams({
      sid: getSessionId(),
      type: props.walletType,
      wallet: props.wallet,
      additionalParams: JSON.stringify(props.additionalParams || {})
    })

    try {
      const response = await fetch(`${vbApiUrl}/vb2/payouts/create`, {
        method: 'POST',
        body: aParams
      })
      res = await response.json()
    } catch (e) {
      //console.log(e)
    }

    const showSnackbar = controller.getSequence('app.showSnackbar')
    const intl = controller.getState('intl.content')
    if (res.error) {
      showSnackbar({
        text: intl.get_money_error,
        ms: 5000,
        type: 'error',
      })
    } else {
      showSnackbar({
        text: intl.get_money_wait,
        ms: 5000,
        type: 'success',
      })
      //store.set(state`auth.coins`, 0)
      store.set(state`auth.usdBalance`, 0)  // !!!!!!!!!!!!!!!!

      const myUid = get(state`auth.uid`)
      const lang = get(state`intl.lang`)
      let walletType = props.walletType === 'yandex' ? 'yndx' : props.walletType === 'bitcoin' ? 'btc' : props.walletType

      // && lang === 'ru'
      /** тут переключалка типов карт */
      if (props.walletType === 'card') {
        walletType = 'card_russia'
      }

      const pymentDataUser = get(state`payment.pymentDataUser`)

      // Последний тип вывода, которым воспользовался пользователь
      pymentDataUser.pymentTypeUser[myUid] = walletType

      // Данные, которые пользователь ввел при выводе средств, чтобы заполнить ими поля формы в следующий раз
      const dataPayment = props.additionalParams || {}
      dataPayment['wallet'] = props.wallet
      try {
        pymentDataUser[myUid][walletType] = dataPayment
      } catch (error) {
        pymentDataUser[myUid] = new Object()
        pymentDataUser[myUid][walletType] = dataPayment
      }

      store.set(state`payment.pymentDataUser`, pymentDataUser)

      sendAmplitudeEvent('payout', {
        sum_usd: Math.round(res.usd * 100) / 100,
        coins: res.oldBalance,
        payout_id: res.payoutId,
        wallet: props.wallet,
        type: props.walletType
      })
    }
  },
  ({ props, store, get }) => store.set('app.payoutData', {
    coins: get(state`auth.coins`),
    wallet: props.wallet,
    type: props.walletType,
  })
]
