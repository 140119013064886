import routed from './routed'
import routeTo from './routeTo'
import acceptWarning from './acceptWarning'
import acceptEula from './acceptEula'
import hideEula from './hideEula'
import hideTopBar from './hideTopBar'
import scrollToTop from './scrollToTop'
import userRedirectStatus from './userRedirectStatus'
import postRouted from './postRouted'
import roomRouted from './roomRouted'
import dialogRouted from './dialogRouted'
import panelsOnPop from './panelsOnPop'
import routeToRoom from './routeToRoom'
import createPanel from './createPanel'
import showSnackbar from './showSnackbar'
import destroyPanel from './destroyPanel'
import onResize from './onResize'
import invalidRouted from './invalidRouted'
import roomTransited from './roomTransited'
import closeSnackbar from './closeSnackbar'
import copyToClipboard from './copyToClipboard'
import createPanelWithoutUrl from './createPanelWithoutUrl'
import destroyPanelImmediately from './destroyPanelImmediately'
import forgotPassword from './forgotPassword'
import answerCall from './answerCall'
import getLastCaller from './getLastCaller'
import askPayoutHttp from './askPayoutHttp'
import askPrivate from './askPrivate'
import addSub from './addSub'
import removeSub from './removeSub'
import changeSub from './changeSub'
import setSubPermission from './setSubPermission'
import startStream from './startStream'
import openSupport from './openSupport'
import openShop from './openShop'
import getAditionalResources from '../chains/getAditionalResources'
import initOneSignal from './initOneSignal'
import postersRouted from './postersRouted'
import dialogsRouted from './dialogsRouted'
import coinsRouted from './coinsRouted'
import payoutRouted from './payoutRouted'
import searchRouted from './searchRouted'
import fansRouted from './fansRouted'
import settingsRouted from './settingsRouted'
import popRoutesHistory from './popRoutesHistory'
import saveOneSignalUserId from './saveOneSignalUserId'
import setOneSignalInited from './setOneSignalInited'
import processingOfRoutes from './processingOfRoutes'
import addContentRouted from './addContentRouted'
import onIframeMessage from './onIframeMessage'
import redirectToRoom from './redirectToRoom'
import loadState from './loadState'
import share from './share'
import setNotificationsButtonMode from './setNotificationsButtonMode'
import prepareAndRouteTo from './prepareAndRouteTo'
import setAnimatedTopBar from './setAnimatedTopBar'
import onScrollEnd from './onScrollEnd'
import openRateUs from './openRateUs'
import leavePostScreen from './leavePostScreen'
import convertCurrency from './convertCurrency'
import exchangeCoins from './exchangeCoins'
import onUsersLoaded from './onUsersLoaded'
import agentTransfer from './agentTransfer'
import stopCall from './stopCall'
import allowNsfw from './allowNsfw'
import contentModerationRouted from './contentModerationRouted'
import complaintStream from './complaint/complaintStream'
import storeSetUtil from '../../../utils/signals/StoreSetUtil'
import storePushUtil from '../../../utils/signals/StorePushUtil'
import storeShiftUtil from '../../../utils/signals/StoreShiftUtil'
import storeMergeUtil from '../../../utils/signals/StoreMergeUtil'
import storeUnshiftUtil from '../../../utils/signals/StoreUnshiftUtil'

export default {
  addSub,
  share,
  allowNsfw,
  stopCall,
  openRateUs,
  openSupport,
  askPayoutHttp,
  askPrivate,
  acceptWarning,
  acceptEula,
  hideEula,
  hideTopBar,
  scrollToTop,
  userRedirectStatus,
  answerCall,
  removeSub,
  changeSub,
  routed,
  routeTo,
  openShop,
  loadState,
  dialogRouted,
  setOneSignalInited,
  prepareAndRouteTo,
  addContentRouted,
  getLastCaller,
  setSubPermission,
  getAditionalResources,
  setNotificationsButtonMode,
  redirectToRoom,
  onIframeMessage,
  processingOfRoutes,
  popRoutesHistory,
  coinsRouted,
  payoutRouted,
  searchRouted,
  fansRouted,
  settingsRouted,
  dialogsRouted,
  saveOneSignalUserId,
  postersRouted,
  postRouted,
  roomRouted,
  invalidRouted,
  createPanel,
  panelsOnPop,
  startStream,
  onResize,
  routeToRoom,
  showSnackbar,
  destroyPanel,
  roomTransited,
  closeSnackbar,
  copyToClipboard,
  createPanelWithoutUrl,
  destroyPanelImmediately,
  forgotPassword,
  initOneSignal,
  setAnimatedTopBar,
  onScrollEnd,
  onUsersLoaded,
  leavePostScreen,
  convertCurrency,
  exchangeCoins,
  agentTransfer,
  contentModerationRouted,
  complaintStream,
  storeSetUtil,
  storePushUtil,
  storeShiftUtil,
  storeMergeUtil,
  storeUnshiftUtil
}
