import React, { Component } from 'react'
import styled from '@emotion/styled'
import propsOverrideCss from '@/utils/propsOverrideCss'
import { Spinner } from './Loading'
import { bool, func, object, string } from 'prop-types'

const Container = styled.div({
  position: 'relative',
})

const Img = styled.img({
  height: '100%',
  width: '100%',
}, propsOverrideCss)

class ImageWithLoader extends Component {
  state = {
    loadedSrc: '',
  }

  render() {
    const {
      style,
      filter,
      objectFit,
      onLoad,
      src,
      onClick,
      alt,
      noSpinner,
      imgStyle,
      map,
      stopPropagation,
      customClass
    } = this.props
    return (
      <Container style={style} className={customClass} onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation()
        }
        if (onClick) {
          onClick()
        }
      }}>
        <Img alt={alt} src={src} style={imgStyle} filter={filter} useMap={map} objectFit={objectFit}
             opacity={(this.state.loadedSrc !== src && !noSpinner) ? 0 : 1}
             onLoad={e => {
               this.setState({ loadedSrc: src })
               if (onLoad) {
                 onLoad(e)
               }
             }}/>
        {this.state.loadedSrc !== src && !noSpinner &&
          <Spinner borderColor="rgba(221, 221, 221, 0.36)" borderTopColor="#ffffff"/>
        }
      </Container>
    )
  }
}

ImageWithLoader.propTypes = {
  alt: string,
  customClass: string,
  filter: string,
  imgStyle: object,
  map: object,
  noSpinner: bool,
  objectFit: string,
  onClick: func,
  onLoad: func,
  src: string,
  stopPropagation: bool,
  style: object
}

export default ImageWithLoader
