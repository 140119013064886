import { state } from 'cerebral'
import controller from '@/controller'
import { PROFILE_TAG, STREAM_THEME_TAG } from '@/utils/postUtils'
import { VIDEO_CHAT_POST } from '../../../components/Panels/createPanelUtil'

export default [
  ({ store, get, props }) => {
    const postNum = parseInt(props.postNum)
    const selectKind = controller.getSequence('upload.selectPostKindModer')
    if (!postNum) {
      store.set('posts.selectedPostId', null)
      store.set('upload.selectedFileUrl', null)
      store.set('upload.title', '')
      store.set('upload.description', '')
      store.set('upload.moderPostStatus', null)
      store.set('upload.selectedTags', [])
      store.set('upload.photoUrl', null)
      store.set('upload.uploadProgress', -1)

      if (props.isProfile) {
        store.set('upload.postKind', 'postProfile')
        selectKind({ postKind: 'postProfile' })
      } else {
        store.set('upload.postKind', VIDEO_CHAT_POST)
        selectKind({ postKind: VIDEO_CHAT_POST })
      }
      return
    }

    //const getPost = controller.getSequence('posts.getPost')
    //getPost({ postId: postNum })

    store.set('posts.selectedPostId', parseInt(postNum))

    const postData = get(state`posts.postsData.${postNum}`)
    if (!postData) {
      if (props.isProfile) {
        store.set('upload.postKind', 'postProfile')
      } else {
        store.set('upload.postKind', VIDEO_CHAT_POST)
      }
      return
    }

    const postStatus = /*get(state`upload.moderPostStatus`) > 0 ? get(state`upload.moderPostStatus`) :*/ postData.statusFromDb

    store.set('upload.postKind', postData.postKind)
    store.set('upload.selectedFileUrl', postData.original)
    store.set('upload.title', postData.originalTitle)
    store.set('upload.description', postData.markdownDescOriginal || postData.originalDesc)
    store.set('upload.moderPostStatus', postStatus)
    store.set('upload.selectedTags', postData.tags || [])
    store.set('upload.subscriptionType', postData.subscriptionType)
    store.set('upload.photoUrl', postData.photoUrl)

    let kind = postData.postKind
    if (!kind) {
      if (postData.tags && postData.tags.indexOf(PROFILE_TAG) !== -1) {
        kind = 'postProfile'
      } else {
        kind = postData.postType === 1 ? 'video' : 'photo'
      }
    }

    selectKind({ postKind: kind })
  }
]
